import React from "react";
import { Link } from 'gatsby';
import SEO from "../components/seo";
import Fade from 'react-reveal/Fade';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section id="404" className="w-screen h-screen">
        <div className="flex flex-col justify-center items-center h-full w-full p-10">
          <Fade bottom duration={1000} distance="30px">
            <h1 className="hero-title text-center dark-blue-text text-6xl mb-10">Sorry, this page does not exist. </h1>
          </Fade>
          <Fade bottom duration={1000} delay={500} distance="30px">
              <Link className="cta-btn cta-btn--hero__404" to="/">          
                Home
              </Link>
          </Fade>
        </div>
      </section>
      </>
);

export default NotFoundPage;